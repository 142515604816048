import React from "react";
import PropTypes from "prop-types";

import {
  Postcode,
  PropertyType,
  PropertySize,
  PropertyValue,
  AppointWhenEA4Me,

} from "../../../formGroups/PropertyDetailsGroup";

export default function EA4Me({ control, register, getValues }) {


  return (
    <>
      <Postcode
        control={control}
        required={true}
        getValues={getValues}
        register={register}
        label="Enter Postcode"
      />
      <PropertyType control={control} required={true} register={register} />
      <PropertySize control={control} required={true} register={register} />
      <PropertyValue control={control} required={true} register={register} />
        <AppointWhenEA4Me control={control} required={true} register={register} />

    </>
  );
}

// PropTypes
EA4Me.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func,
};
